import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
import { fFormatDate } from '../../utils/formatTime';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    meta: {
        currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0,
    },
    affiliations: [],
    affiliation: null,
};
// ----------------------------------------------------------------------
export const getOneAffiliation = createAsyncThunk('affiliation/getOneAffiliation', async ({ id }) => {
    let data;
    try {
        const response = await axios.get(`/affiliations/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getAffiliations = createAsyncThunk('affiliation/getAffiliations', async ({ isPaging = false, page = '', rowsPerPage = '', orderBy = 'createdAt', order = 'desc', filterName = '', filterEndDate = '', filterStartDate = '', }) => {
    let data;
    const start = filterEndDate && filterEndDate[0] !== '' && filterEndDate[1] !== ''
        ? `${fFormatDate(filterEndDate[0])},${fFormatDate(filterEndDate[1])}`
        : '';
    const end = filterStartDate && filterStartDate[0] !== '' && filterStartDate[1] !== ''
        ? `${fFormatDate(filterStartDate[0])},${fFormatDate(filterStartDate[1])}`
        : '';
    const queryParams = {
        search: {
            'agency.name': filterName,
            startAt: start,
            endAt: end,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
    };
    try {
        const url = createDynamicURL('/affiliations', queryParams);
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createAffiliation = createAsyncThunk('affiliation/createAffiliation', async (Data) => {
    let data;
    try {
        const response = await axios.post(`/affiliations`, Data);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const editAffiliation = createAsyncThunk('affiliation/editAffiliation', async ({ id, Data }) => {
    let data;
    try {
        const response = await axios.put(`/affiliations/${id}`, Data);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteAffiliation = createAsyncThunk('affiliation/deleteAffiliation', async ({ id, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/affiliations/${id}`);
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getAffiliations({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteManyAffiliations = createAsyncThunk('affiliation/deleteManyAffiliations', async ({ ids, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/affiliations/many`, { data: { ids } });
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getAffiliations({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'affiliation',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAffiliations.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getAffiliations.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.affiliations = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getAffiliations.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getOneAffiliation.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getOneAffiliation.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.affiliation = action.payload;
        })
            .addCase(getOneAffiliation.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(createAffiliation.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(createAffiliation.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(createAffiliation.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(editAffiliation.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(editAffiliation.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(editAffiliation.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(deleteAffiliation.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(deleteAffiliation.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(deleteAffiliation.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(deleteManyAffiliations.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(deleteManyAffiliations.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(deleteManyAffiliations.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice;
